import { Box } from '@material-ui/core'
import { CardCta, CardCtaProps } from 'common/components/molecules/CardCta'
import { RichText } from 'common/components/molecules/RichText'
import { Text } from 'common/components/molecules/Text'
import React, { CSSProperties } from 'react'

export interface CardProps {
    callToAction?: CardCtaProps | CardCtaProps[]
    icon?: string
    disablePadding?: boolean
    text: string
    title: string
    subtitle?: string
    style?: CSSProperties
    iconSize?: 'md' | 'lg'
    helpcentre?: boolean
}

const iconSizeList = {
    desktop: {
        md: 40,
        lg: 64,
    },
}

export const Card: React.FC<CardProps> = ({
    callToAction,
    disablePadding,
    icon,
    text,
    title,
    subtitle,
    style,
    iconSize = 'md',
    helpcentre,
}) => {
    return (
        <>
            <Box
                bgcolor='#fff'
                borderRadius={20}
                data-testid='Card'
                display='flex'
                flexDirection='column'
                height='100%'
                px={!disablePadding ? 9 : 0}
                pt={15}
                pb={!disablePadding ? 10 : 0}
                style={{ color: '#5261AC', ...style }}
            >
                {icon && (
                    <img
                        alt='Product Card Icon'
                        src={icon}
                        height={iconSizeList['desktop'][iconSize]}
                        width={iconSizeList['desktop'][iconSize]}
                    />
                )}
                <Text
                    box={{ mt: 7, mb: !subtitle ? 5 : 1 }}
                    label={title}
                    variant='h5'
                    component='h2'
                />
                {subtitle && (
                    <Text
                        box={{ color: '#9D98B0', mb: 5 }}
                        label={subtitle}
                        variant='subtitle1'
                        component='h3'
                    />
                )}
                <RichText
                    color='textSecondary'
                    html
                    label={text}
                    variant='body2'
                />
                {callToAction &&
                    Array.isArray(callToAction) &&
                    callToAction.map((item: CardCtaProps) => {
                        return (
                            <>
                                {item?.label && (
                                    <>
                                        <Box flex={1} mt={11} />
                                        <CardCta
                                            {...item}
                                            helpcentre={helpcentre}
                                        />
                                    </>
                                )}
                            </>
                        )
                    })}
                {callToAction !== undefined &&
                    !Array.isArray(callToAction) &&
                    callToAction?.label !== '' && (
                        <>
                            <Box flex={1} mt={11} />
                            <CardCta
                                {...callToAction}
                                helpcentre={helpcentre}
                            />
                        </>
                    )}
            </Box>
        </>
    )
}
