import { Box, Grid, GridProps, Hidden } from '@material-ui/core'
import { BasicButton } from 'common/components/atoms/BasicButton'
import { Card } from 'common/components/molecules/Card'
import { CardCtaProps } from 'common/components/molecules/CardCta'
import { Spacer } from 'common/components/molecules/Spacer'
import { Text } from 'common/components/molecules/Text'
import { useDesktop } from 'common/hooks/useDesktop'
import React from 'react'

interface Benefit {
    callToAction?: CardCtaProps
    icon: string
    text: string
    title: string
}

export interface BenefitsProps {
    benefits: Benefit[]
    subtitle: string
    title: string
    heading?: string
    iconSize?: 'md' | 'lg'
    ctaLabel?: string
    ctaUrl?: string
    noWrap?: boolean
    helpcentre?: boolean
    alignTextLeft?: boolean
}

export const Benefits: React.FC<BenefitsProps> = ({
    benefits = [],
    heading,
    subtitle,
    title,
    iconSize,
    ctaLabel,
    ctaUrl,
    noWrap,
    helpcentre = false,
    alignTextLeft = false,
}) => {
    const desktop = useDesktop()
    const columns =
        benefits.length >= 3 ? (noWrap ? benefits.length : 3) : benefits.length
    return (
        <Box
            data-testid='Benefits'
            display='flex'
            flexDirection='column'
            alignItems='center'
            component='section'
        >
            {(heading || title) && (
                <>
                    <Box
                        mx={[0, 0, 24]}
                        width={alignTextLeft ? '100%' : undefined}
                    >
                        {heading && (
                            <Text
                                label={heading}
                                style={{
                                    marginBottom: '32px',
                                    color: '#4DB7AB',
                                    fontWeight: 700,
                                    textTransform: 'uppercase',
                                }}
                                component='h4'
                            />
                        )}
                        <Box
                            mb={6}
                            textAlign={
                                desktop
                                    ? alignTextLeft
                                        ? 'unset'
                                        : 'center'
                                    : undefined
                            }
                        >
                            <Text
                                label={title}
                                variant={desktop ? 'h4' : 'h2'}
                                component='h1'
                            />
                        </Box>

                        <Grid container spacing={6}>
                            <Hidden smDown>
                                <Grid item xs={2} />
                            </Hidden>
                            <Grid item xs={12} md={8}>
                                <Text
                                    align={desktop ? 'center' : undefined}
                                    color='textSecondary'
                                    html
                                    label={subtitle}
                                    variant='subtitle1'
                                    component='p'
                                />
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={2} />
                            </Hidden>
                        </Grid>
                    </Box>
                    <Spacer variant={desktop ? 'xs' : 'sm'} />
                </>
            )}
            <Grid container spacing={6}>
                {benefits.map((benefit) => (
                    <Grid
                        key={benefit.title}
                        item
                        xs={12}
                        md={(12 / columns) as GridProps['md']}
                    >
                        <Card
                            {...benefit}
                            iconSize={iconSize}
                            helpcentre={helpcentre}
                        />
                    </Grid>
                ))}
            </Grid>
            {ctaLabel && (
                <Box width={alignTextLeft ? ['auto', 'auto', '100%'] : []}>
                    <Spacer variant='xs' />
                    <BasicButton
                        label={ctaLabel}
                        url={ctaUrl}
                        analyticsId={ctaLabel}
                        analyticsEventName='secondaryButtonClick'
                    />
                </Box>
            )}
        </Box>
    )
}
