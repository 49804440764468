import { Spacer } from 'common/components/molecules/Spacer'
import { Wrapper } from 'common/components/molecules/Wrapper'
import { Benefits, BenefitsProps } from 'common/components/organisms/Benefits'
import {
    Jumbotron,
    JumbotronProps,
} from 'common/components/organisms/Jumbotron'
import { Layout, LayoutProps } from 'common/components/organisms/Layout'
import React from 'react'

export interface HelpCentreProps {
    benefits: BenefitsProps
    jumbotron: JumbotronProps
    layout: LayoutProps
}

export const HelpCentre: React.FC<HelpCentreProps> = ({
    benefits,
    jumbotron,
    layout,
}) => {
    return (
        <Layout {...layout}>
            <Jumbotron {...jumbotron} />
            <Wrapper component='section'>
                <Spacer variant='md' />
                <Benefits {...benefits} helpcentre={true} />
            </Wrapper>
        </Layout>
    )
}
