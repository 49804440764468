import { getJumbotronData } from 'common/components/organisms/Jumbotron'
import { HelpCentre } from 'common/components/templates/HelpCentre'
import { getLayoutData } from 'common/utils/getLayoutData'
import React from 'react'

export interface HelpCentrePageProps {
    pageContext: any
}

export const HelpCentrePage: React.FC<HelpCentrePageProps> = ({
    pageContext
}) => {
    return <HelpCentre {...getHelpCentreData(pageContext)} />
}

export default HelpCentrePage

export const getHelpCentreData = (data: any) => ({
    layout: getLayoutData(data),
    alerts: {
        alerts: (data.alerts__alerts || []).map((alert: any) => alert.text)
    },
    jumbotron: getJumbotronData(data),
    benefits: {
        benefits: (data.threecards__cards || []).map((card: any) => ({
            ...card,
            callToAction: {
                label: card.card_cta_0396ba7__cta_label,
                variant: card.card_cta_0396ba7__cta_variant,
                url: card.card_cta_0396ba7__cta_url
            }
        })),
        subtitle: data.threecards__subtitle,
        title: data.threecards__title
    }
})
